import { createSlice } from '@reduxjs/toolkit'

export const organigrammeSlice = createSlice({
    name: 'organigramme',
    initialState: {
        index: 0
    },
    reducers: {
        handleOrganigrammeIndexChange: (state, action) => {
            state.index = action.payload
        } 
    }
})

export const {
    handleOrganigrammeIndexChange
} = organigrammeSlice.actions

export default organigrammeSlice.reducer