import { createSlice } from '@reduxjs/toolkit'

export const imageCanvasSlice = createSlice({
    name: 'imageCanvas',
    initialState: {
        nb_image: 0
    },
    reducers: {
        handleNbImageChange: (state, action) => {
            state.nb_image = action.payload
        }
    }
})

export const {
    handleNbImageChange
} = imageCanvasSlice.actions

export default imageCanvasSlice.reducer