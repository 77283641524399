import { createSlice } from '@reduxjs/toolkit'

export const visuSlice = createSlice({
    name: 'visu',
    initialState: {
        images: [],
        comptes: [],
        open: false,
        openCompte: false
    },
    reducers: {
        handleImagesChange: (state, action) => {
            state.images = action.payload
        },
        handleOpenVisuImage: (state, action) => {
            state.open = action.payload
        },
        handleComptesChange: (state, action) => {
            state.comptes = action.payload
        },
        handleOpenVisuCompte: (state, action) => {
            state.openCompte = action.payload
        }        
    }
})

export const {
    handleImagesChange,
    handleOpenVisuImage,
    handleComptesChange,
    handleOpenVisuCompte
} = visuSlice.actions

export default visuSlice.reducer