import { createSlice } from '@reduxjs/toolkit'

export const separationSlice = createSlice({
    name: 'separation',
    initialState: {
        image: null,
        categorie: 0,
        sousCategorie: 0,
        sousSousCategorie: 0
    },
    reducers: {
        handleImageChange: (state, action) => {
            state.image = action.payload
        },
        handleCategorieChange: (state, action) => {
            state.categorie = action.payload
        },
        handleSousCategorieChange: (state, action) => {
            state.sousCategorie = action.payload
        },
        handleSousSousCategorieChange: (state, action) => {
            state.sousSousCategorie = action.payload
        }
    }
})

export const {
    handleImageChange,
    handleCategorieChange,
    handleSousCategorieChange,
    handleSousSousCategorieChange
} = separationSlice.actions

export default separationSlice.reducer