import { createSlice } from '@reduxjs/toolkit'

//dates: [start, end]
//type: date_scan, date_ecriture
export const dateFilterSlice = createSlice({
    name: 'dateFilter',
    initialState: {
        open: false,
        dates: [],
        filterType: 0,
        event_index: 0
    },
    reducers: {
        handleOpenChange: (state, action) => {
            state.open = action.payload
        },
        handleDatesChange: (state, action) => {
            state.dates = action.payload
        },
        handleFilterTypeDateChange: (state, action) => {
            state.filterType = action.payload
        },
        handleDateFilterEventChange: (state, action) => {
            state.event_index = action.payload
        }
    }
})

export const {
    handleOpenChange,
    handleDatesChange,
    handleFilterTypeDateChange,
    handleDateFilterEventChange
} = dateFilterSlice.actions

export default dateFilterSlice.reducer
