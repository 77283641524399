// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import auth from "./auth"
import filtre from "./filtre"
import saisieBanque from "./saisieBanque"
import visu from "./visu"
import loading from "./loading"
import organigramme from "./organigramme"
import user from "./user"
import saisieUniverselle from "./saisieUniverselle"
import clients from "./client"
import dateFilter from "./dateFilter"
import agenda from "./agenda"
import codeAnalytique from "./codeAnalytique"
import showFiltreBanque from "./showFiltreBanque"
import ob from "./ob"
import separation from "./separation"
import imageCanvas from "./imageCanvas"
import indicateur from "./indicateur"
import blockLoading from "./blockLoading"
import pm from "./banques/pm"
import rapprochement from "./banques/rapprochement"
import indicateurSetting from "./etats/indicateurSetting"

const rootReducer = { 
    navbar, 
    layout, 
    auth, 
    filtre, 
    saisieBanque, 
    visu, 
    loading, 
    organigramme, 
    user, 
    saisieUniverselle, 
    clients, 
    dateFilter, 
    agenda,
    codeAnalytique,
    showFiltreBanque,
    ob,
    separation,
    imageCanvas,
    indicateur,
    blockLoading,
    pm,
    rapprochement,
    indicateurSetting
}

export default rootReducer
