import { createSlice } from '@reduxjs/toolkit'

export const showFiltreBanqueSlice = createSlice({
    name: 'showFiltreBanque',
    initialState: {
        show: false
    },
    reducers: {        
        handleShowChange: (state, action) => {
            state.show = action.payload
        }
    }
})

export const {
    handleShowChange
} = showFiltreBanqueSlice.actions

export default showFiltreBanqueSlice.reducer
