import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'
import { API_URL } from '../../../services/Utils/urls'
import { loadingParams } from '../../../services/Utils/utils'

const setLoading = (load) => {
  if (!load) document.body.classList.remove('loading-indicator')
  else document.body.classList.add('loading-indicator')
}

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  axiosNumber = 0

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // ** Request Interceptor
    axios.interceptors.request.use(
      config => {
        let loading = true 
        this.axiosNumber++

        if (config?.method === 'get') {
          if (config.params) {
            loading = typeof config.params[loadingParams] === 'boolean' ? config.params[loadingParams] : true 
          }
        } else if (config?.method === 'post') {
          if (config.data) {
            loading = typeof config.data[loadingParams] === 'boolean' ? config.data[loadingParams] : true 
          }
        }
        
        setLoading(loading)
        config.baseURL = API_URL
        // ** Get token from localStorage
        const accessToken = this.getToken()
 
        // ** If token is present add it to request's Authorization Header
        if (accessToken && typeof config.headers && typeof config.headers.Authorization === 'undefined') {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }

        return config
      },
      error => {
        //console.log('error')
        //setLoading(false)
        return Promise.reject(error)
      }
    )

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      response => {
        this.axiosNumber--
        setLoading(this.axiosNumber !== 0)

        //setLoading()
        return response
      },
      error => {
        this.axiosNumber--
        setLoading(this.axiosNumber !== 0)
        //setLoading()

        //document.body.classList.remove('loading-indicator')
        // ** const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // ** if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // ** Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // ** Make sure to assign accessToken according to your response.
              // ** Check: https://pixinvent.ticksy.com/ticket/2413870
              // ** Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(axios(originalRequest))
            })
          })
          return retryOriginalRequest
        }

        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    const str = localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
    if (str && str.startsWith('"') && str.endsWith('"')) return str.slice(1, -1)
    return str
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refresh_token: this.getRefreshToken()
    })
  }
}
