import { createSlice } from '@reduxjs/toolkit'
import _ from 'underscore'


export const clients = createSlice({
    name: 'clients',
    initialState: {
        allClients: [],
        filtredClient: [],
        client: {}
    },
    reducers: {
        handelFetchAllClient: (state, action) => {
            state.allClients = action.payload
        },
        handelFilter: (state, { payload }) => {
            state.filtredClient = payload
        },
        resetSingleClient: state => {
            state.client = { }
        },
        fetchSingleClientDetails: (state, { payload }) => {
            state.client = payload
        },
        addSingleClient: (state, { payload }) => {
            if (payload.data.status === payload.currentFilter || payload.currentFilter === 2) {
                state.filtredClient = [payload.data, ...state.filtredClient]
            }
            state.allClients = [payload.data, ...state.allClients]
        },
        updateSingleClient: (state, { payload }) => {
            let data = state.filtredClient
            const match = _.find(data, function (item) { return item.id === payload.data.id })
            if (match) {
                match.status = payload.data.status
                match.id = payload.data.id
                match.nom = payload.data.nom
            }
            if (payload.data.status !== payload.currentFilter && payload.currentFilter !== 2) {
                data = data.filter(d => d.id !== payload.data.id)
            }
            state.filtredClient = data
        },
        addSiteForClient: (state, { payload }) => {
            state.client.sites = [...state.client.sites, payload]
        }

    }
})


export const {
    handelFetchAllClient,
    handelFilter,
    resetSingleClient,
    fetchSingleClientDetails,
    addSingleClient,
    addSiteForClient,
    updateSingleClient
} = clients.actions

export default clients.reducer
