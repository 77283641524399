import { createSlice } from '@reduxjs/toolkit'

export const indicateurSlice = createSlice({
    name: 'indicateur',
    initialState: {
        column: 2,
        selectedIndicateurPack: null,
        indicateursTemps: []
    },
    reducers: {
        handleColumnChange: (state, action) => {
            state.column = action.payload
        },
        handleSelectedIndicateurPackChange: (state, action) => {
            state.selectedIndicateurPack = action.payload
        },
        handleIndicateursTempsChange: (state, action) => {
            state.indicateursTemps = action.payload
        }
    }
})

export const {
    handleColumnChange,
    handleSelectedIndicateurPackChange,
    handleIndicateursTempsChange
} = indicateurSlice.actions

export default indicateurSlice.reducer