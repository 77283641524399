import { createSlice } from '@reduxjs/toolkit'

export const obSlice = createSlice({
    name: 'ob',
    initialState: {
        filterBy: 0,
        images: [],
        souscategorie: 0,
        imageSelected: null,
        comptes: [],
        save: 0,
        datas: [],
        details: [],
        columns: []
    },
    reducers: {
        handleFilterByChange: (state, action) => {
            state.filterBy = action.payload
        },
        handleImagesObsChange: (state, action) => {
            state.images = action.payload
        },
        handleSousCategorieChange: (state, action) => {
            state.souscategorie = action.payload
        },
        handleImageSelected: (state, action) => {
            state.imageSelected = action.payload
        },
        handleComptesChange: (state, action) => {
            state.comptes = action.payload
        },
        handleDatasChange: (state, action) => {
            state.datas = action.payload
        },
        handleDetailsChange: (state, action) => {
            state.details = action.payload
        },
        handleColumnsChange: (state, action) => {
            state.columns = action.payload
        }
    }
})

export const {
    handleFilterByChange, 
    handleImagesObsChange, 
    handleSousCategorieChange,
    handleImageSelected,
    handleComptesChange,
    handleDatasChange,
    handleDetailsChange,
    handleColumnsChange
} = obSlice.actions

export default obSlice.reducer