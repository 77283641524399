import { createSlice } from '@reduxjs/toolkit'

export const blockLoadingSlice = createSlice({
    name: 'blockLoading',
    initialState: {
        block: false
    },
    reducers: {
        handleBlockChange: (state, action) => {
            state.block = action.payload
        }
    }
})

export const {
    handleBlockChange
} = blockLoadingSlice.actions

export default blockLoadingSlice.reducer