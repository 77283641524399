import { createSlice } from '@reduxjs/toolkit'

export const contratDpSlice = createSlice({
    name: 'pm',
    initialState: {
        openException: false,
        openContratDp: false,
        selectedContratDp: null,
        reloadPm: 0
    },
    reducers: {
        handleOpenExeptionChange: (state, action) => {
            state.openException = action.payload
        },
        handleOpenContratDpChange: (state, action) => {
            state.openContratDp = action.payload
        },
        handleSelectedContratDpChange: (state, action) => {
            state.selectedContratDp = action.payload
        },
        handleReloadPmChange: (state, action) => {
            state.reloadPm = action.payload
        }        
    }
})

export const {
    handleOpenExeptionChange,
    handleOpenContratDpChange,
    handleSelectedContratDpChange,
    handleReloadPmChange
} = contratDpSlice.actions

export default contratDpSlice.reducer