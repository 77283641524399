import { createSlice } from '@reduxjs/toolkit'

export const indicateurSettingSlice = createSlice({
    name: 'indicateurSetting',
    initialState: {
        group_active: 0,
        indicateur_groups: [],
        pack_active: 0,
        operande_pusher: false
    },
    reducers: {
        handleGroupActiveChange: (state, action) => {
            state.group_active = action.payload
        },
        handleIndicateurGroupsChange: (state, action) => {
            state.indicateur_groups = action.payload
        },
        handlePackActiveChange: (state, action) => {
            state.pack_active = action.payload
        },
        handleOperandePusherChange: (state, action) => {
            state.operande_pusher = action.payload
        }        
    }
})

export const {
    handleGroupActiveChange,
    handleIndicateurGroupsChange,
    handlePackActiveChange,
    handleOperandePusherChange
} = indicateurSettingSlice.actions

export default indicateurSettingSlice.reducer