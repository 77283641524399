import { createSlice } from '@reduxjs/toolkit'

export const codeAnalytiqueSlice = createSlice({
    name: 'codeAnalytique',
    initialState: {
        code_analytiques: [],
        open: false,
        openAdd: false,
        typeAdd: 0, // 0: analytique, 1: section
        code_analytique: 0
    },
    reducers: {
        handleCodeAnalytiquesChange: (state, action) => {
            state.code_analytiques = action.payload
        },
        handleCodeAnalytiqueOpenChange: (state, action) => {
            state.open = action.payload
        },
        handleCodeAnalytiqueOpenAddChange: (state, action) => {
            state.openAdd = action.payload
        },
        handleCodeAnalytiqueTypeAddChange: (state, action) => {
            state.typeAdd = action.payload
        },
        handleCodeAnalytiqueChange: (state, action) => {
            state.code_analytique = action.payload
        }
    }
})

export const {
    handleCodeAnalytiquesChange,
    handleCodeAnalytiqueOpenChange,
    handleCodeAnalytiqueOpenAddChange,
    handleCodeAnalytiqueTypeAddChange,
    handleCodeAnalytiqueChange
} = codeAnalytiqueSlice.actions

export default codeAnalytiqueSlice.reducer