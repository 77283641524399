import { createSlice } from '@reduxjs/toolkit'

export const saisieBanqueSlice = createSlice({
    name: 'saisieBanque',
    initialState: {
        obImages: [],
        rbImages: [],
        banqueComptes: [],
        pccs: [],
        tiers: [],
        banqueTypes: [],
        soussouscategories: [],
        loading: false,
        rbSelectedImage: null,
        obSelectedImage: null
    },
    reducers: {
        fetchRbImages: (state, action) => {
            state.rbImages = action.payload
        },
        fetchObImages: (state, action) => {
            state.obImages = action.payload
        },
        fetchBanqueComptes: (state, action) => {
            state.banqueComptes = action.payload
        },
        toggleLoading: (state, action) => {
            state.loading = action.payload
        },
        handleSelectRbImage: (state, action) => {
            state.rbSelectedImage = action.payload
        },
        handleSelectObImage: (state, action) => {
            state.obSelectedImage = action.payload
        },
        fetchTiers: (state, action) => {
            state.tiers = action.payload
        },
        fetchPccs: (state, action) => {
            state.pccs = action.payload
        },
        fetchBanqueTypes: (state, action) => {
            state.banqueTypes = action.payload
        },
        fetchSoussouscategories: (state, action) => {
            state.soussouscategories = action.payload
        }
    }
})

export const {
    fetchRbImages,
    fetchObImages,
    fetchTiers,
    fetchPccs,
    fetchBanqueTypes,
    fetchBanqueComptes,
    fetchSoussouscategories,
    toggleLoading,
    handleSelectRbImage,
    handleSelectObImage   
} = saisieBanqueSlice.actions

export default saisieBanqueSlice.reducer