import { createSlice } from '@reduxjs/toolkit'

export const rapprochementSlice = createSlice({
    name: 'rapprochement',
    initialState: {
        indexModeSaisie: 0
    },
    reducers: {
        handleIndexModeSaisieChange: (state, action) => {
            state.indexModeSaisie = action.payload
        }
    }
})

export const {
    handleIndexModeSaisieChange
} = rapprochementSlice.actions

export default rapprochementSlice.reducer