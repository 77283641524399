import { createSlice } from '@reduxjs/toolkit'

export const loadingSlice = createSlice({
    name: 'loading',
    initialState: {
        nbRequest: 0
    },
    reducers: {
        handleNbRequest: (state, action) => {
            state.nbRequest = action.payload
        }
    }
})

export const {
    handleNbRequest
} = loadingSlice.actions

export default loadingSlice.reducer